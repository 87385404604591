import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function Login() {
  const { state } = useLocation();
  const roleName = state?.role;

  const [phone, setPhone] = useState("");
  const [role, setRole] = useState(roleName);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      navigate("/filmmaker/dashboard");
    }
  }, [navigate]);

  const handleSendOtp = async () => {
    if (!phone || !/^\d{10}$/.test(phone)) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://ott-ep3g.onrender.com/api/v1/users/register-or-login",
        {
          mobile: phone,
          role: role,
        }
      );

      if (response.data && response.data.message) {
        setOtpSent(true);
      } else {
        alert("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to verify OTP
  const handleVerifyOtp = async () => {
    if (!otp || otp.length !== 6) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://ott-ep3g.onrender.com/api/v1/users/verify-otp",
        {
          mobile: phone,
          otp: otp,
        }
      );

      if (response.data && response.data.message) {
        if (!response.data.isProfileComplete) {
          navigate(`/register?userId=${response.data.userId}`);
        } else {
          const { token } = response.data;
          localStorage.setItem("authToken", token);

          navigate("/filmmaker/dashboard");
        }
      } else {
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("An error occurred while verifying OTP. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-bg vh-lg-100 vw-lg-100 d-flex align-items-center">
        <div className="container h-100d-flex align-items-center py-lg-0 py-8">
          <div className="row g-0">
            <div className="col-lg-5 col-md-5 col-12 d-flex align-items-end">
              <div className="w-100">
                {!otpSent ? (
                  <div className="p-lg-7 p-5 bg-white rounded-4 w-100">
                    {role === "investor" ? (
                      <>
                        <p className="fst-italic fs-4 text-primary fw-semibold">
                          Benefit for Investor
                        </p>
                        <ol className="mb-0">
                          <li>Access to pre-market entertainment ventures.</li>
                          <li>Better Return on Investment</li>
                          <li>Diversified Portfolio Options</li>
                          <li>
                            Lower Entry Barriers, Flexible Investment Amounts
                          </li>
                          <li>Sustainable Long-Term Success</li>
                          <li>Faster Payback and Revenue Generation</li>
                          <li>Stronger Relationships and Partnerships</li>
                          <li>Transparent Transactions.</li>
                          <li>
                            Flexibility and explore alternative investment
                          </li>
                        </ol>
                      </>
                    ) : (
                      <>
                        <p className="fst-italic fs-4 text-primary fw-semibold">
                          Benefit for Filmmaker
                        </p>
                        <ol className="mb-0">
                          <li>Financial Support</li>
                          <li>Risk Mitigation</li>
                          <li>Creative and Impactful Content Creation</li>
                          <li>Attraction of High-Quality Talent</li>
                          <li>Diversity of Projects</li>
                          <li>Creative Freedom</li>
                          <li>Quick and easy access to OTT channels.</li>
                          <li>Scalable Solutions</li>
                          <li>Streamlined Process</li>
                        </ol>
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-5 offset-lg-2 col-md-7 col-12">
              <div className="p-5 rounded-4 bg-white h-100">
                <div className="m-lg-3 m-2">
                  <h2 className="lh-1 mb-3">
                    <i
                      className="bi bi-arrow-left cursor-pointer"
                      onClick={() => navigate(-1)}
                    ></i>
                  </h2>
                  {!otpSent ? (
                    <h2 className="lh-sm mb-5">
                      Get started with your<br />phone number <i className="bi bi-phone text-danger"></i>
                    </h2>
                  ) : (
                    <>
                      <h2 className="lh-1">OTP Authentication</h2>
                      <p className="mb-5 lh-1">
                        Please enter the OTP sent to your phone to complete the
                        verification process.
                      </p>
                    </>
                  )}
                  {!otpSent ? (
                    <>
                      <div>
                        <div className="row g-2">
                          <div className="mb-2 mb-md-0 col-6">
                            <button
                              className={`border border-1 d-flex flex-column align-items-center w-100 ${
                                role === "investor" ? "bg-light" : "bg-white"
                              } shadow-sm p-4 rounded-4`}
                              onClick={() => setRole("investor")}
                            >
                              <span>Investor</span>
                              <img
                                src="https://cdn.prod.website-files.com/660fec29bd77a271c07eb5b6/66718347d3a6d3a05cfd0fdd_Frame%2037746%20(2)%20(1).png"
                                className="mt-3"
                                width="80"
                              />
                            </button>
                          </div>
                          <div className="mb-2 mb-md-0 col-6">
                            <button
                              className={`border border-1 d-flex flex-column align-items-center w-100 ${
                                role === "content_producer"
                                  ? "bg-light"
                                  : "bg-white"
                              } shadow-sm p-4 rounded-4`}
                              onClick={() => setRole("content_producer")}
                            >
                              <span>Filmmaker</span>
                              <img
                                src="https://cdn.prod.website-files.com/660fec29bd77a271c07eb5b6/66717f3f1ca65dd5891f5132_Frame%2037746%20(1).png"
                                className="mt-3"
                                width="80"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label>
                          Phone No{" "}
                          <i className="bi bi-info-circle-fill text-secondary ms-1"></i>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="..."
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>

                      <button
                        onClick={handleSendOtp}
                        class="btn btn-primary btn-lg mt-4 w-100 rounded-3 fs-5"
                        disabled={loading}
                      >
                        {loading ? (
                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          "Send OTP"
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="col-5 mt-4">
                        <label>
                          OTP{" "}
                          <i className="bi bi-info-circle-fill text-secondary ms-1"></i>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="..."
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>

                      <button
                        onClick={handleVerifyOtp}
                        class="btn btn-primary btn-lg mt-4 w-100 rounded-3 fs-5"
                        disabled={loading}
                      >
                        {loading ? (
                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </>
                  )}
                  <div className="mt-3 px-6">
                    <p className="mb-0 text-center lh-sm">
                      By continuing you accept the{" "}
                      <Link
                        to="/terms-of-use"
                        className="text-inherit fw-semibold"
                      >
                        <u>T&C Apply</u>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
