import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";

function Profile() {
  const [profileData, setProfileData] = useState({
    name: "",
    phone: "",
    occupation: "Salaried",
    participated: [],
    invoiceReason: [],
    referralCode: "",
    accountNumber: "",
    ifscCode: "",
    bankName: "",
    accountHolderName: "",
    branch: "",
    cinNo: "",
    panNo: "",
    gstNo: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user profile data
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://ott-ep3g.onrender.com/api/v1/users/profile/"
        );
        setProfileData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch profile data");
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  // Update profile data
  const updateProfile = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        "https://ott-ep3g.onrender.com/api/v1/users/profile/",
        profileData
      );
      alert("Profile updated successfully!");
      setLoading(false);
    } catch (err) {
      setError("Failed to update profile");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e, groupName) => {
    const { value, checked } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [groupName]: checked
        ? [...prev[groupName], value]
        : prev[groupName].filter((item) => item !== value),
    }));
  };

  return (
    <>
      <Header />

      <section class="pt-5 mt-4 pb-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-6 col-12">
              <div class="rounded-top"></div>
              <div class="card bg-light px-4 pt-2 pb-4 shadow-sm rounded-top-0 rounded-bottom-0 rounded-bottom-md-2">
                <div class="d-flex align-items-end justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                      <img
                        src="https://geeksui.codescandy.com/geeks/assets/images/avatar/avatar-1.jpg"
                        class="avatar-xl rounded-circle border border-4 border-white position-relative"
                        alt="avatar"
                      />
                      <a
                        href="#"
                        class="position-absolute top-0 end-0"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="Verified"
                      >
                        <img
                          src="https://geeksui.codescandy.com/geeks/assets/images/svg/checked-mark.svg"
                          alt="checked"
                          height="30"
                          width="30"
                        />
                      </a>
                    </div>
                    <div class="lh-1">
                      <h2 class="mb-0">Nybaex India</h2>
                      <p class="mb-0 d-block">@nybaex</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mb-7">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="mx-5 my-3">
                  <h2 className="lh-1 mb-2">Account Details</h2>
                  <div className="row g-5">
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Your Name"
                          value={profileData.name}
                          onChange={handleInputChange}
                          readOnly
                        />
                      </div>
                      <div className="mt-4">
                        <label>Phone No.</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Phone No."
                          value={profileData.phone}
                          onChange={handleInputChange}
                          readOnly
                        />
                      </div>
                      <div className="mt-3">
                        <label>What is your occupation?</label>
                        <select
                          className="form-control mt-1"
                          name="occupation"
                          value={profileData.occupation}
                          onChange={handleInputChange}
                        >
                          <option>Salaried</option>
                          <option>Self-employed</option>
                          <option>Professional</option>
                          <option>Retired</option>
                        </select>
                      </div>
                      <div className="mt-4">
                        <label>Have you participated in any of these?</label>
                        <div className="mt-1">
                          {[
                            "Invoice Discounting",
                            "Real Estate",
                            "Stocks/Market Funds",
                            "Bonds/FD/RD",
                            "AIF (Alternative Investment Funds)",
                            "Startups",
                            "Portfolio Management Services",
                          ].map((item) => (
                            <div className="form-check" key={item}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item}
                                checked={profileData.participated.includes(
                                  item
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "participated")
                                }
                              />
                              <label className="form-check-label">{item}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div>
                        <label>
                          Why are you opting for Invoice Discounting in movies?
                        </label>
                        <div className="mt-1">
                          {[
                            "High Return - P.A",
                            "Secured – Confirmed cash flows from top OTTs",
                            "Short Term – Receive return within 1-12 months",
                            "Diversification",
                            "Other",
                          ].map((item) => (
                            <div className="form-check" key={item}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item}
                                checked={profileData.invoiceReason.includes(
                                  item
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "invoiceReason")
                                }
                              />
                              <label className="form-check-label">{item}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="mt-4">
                        <label>Referral Code (Optional)</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Referral Code"
                          name="referralCode"
                          value={profileData.referralCode}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <hr />
                  </div>
                  <h2 className="lh-1 mt-5 mb-2">Payment Details</h2>
                  <div className="row g-5">
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>Account Number</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Account Number"
                          name="accountNumber"
                          value={profileData.accountNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label>IFSC Code</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Account Number"
                          name="ifscCode"
                          value={profileData.ifscCode}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label>Bank Name</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Bank Name"
                          name="bankName"
                          value={profileData.bankName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>A/C Holder Name</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="A/C Holder Name"
                          name="accountHolderName"
                          value={profileData.accountHolderName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label>Branch</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="Branch"
                          name="branch"
                          value={profileData.branch}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <hr />
                  </div>
                  <h2 className="lh-1 mt-5 mb-2">KYC Details</h2>
                  <div className="row g-5">
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>CIN No.</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="CIN No."
                          name="cinNo"
                          value={profileData.cinNo}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mt-4">
                        <label>PAN No.</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="PAN No."
                          name="panNo"
                          value={profileData.panNo}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mt-4">
                        <label>GST No.</label>
                        <input
                          type="text"
                          className="form-control mt-1"
                          placeholder="GST No."
                          name="gstNo"
                          value={profileData.gstNo}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={updateProfile}
                      class="btn btn-warning btn-lg mt-4 px-5 rounded-3 fs-5 mt-8 text-white"
                    >
                      <span className="mx-4">Update Account</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
