import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ContentDashboard from "./pages/Filmmaker/Dashboard";
import Projects from "./pages/Filmmaker/Projects";
import Ready from "./pages/Filmmaker/Ready";
import Profile from "./pages/Filmmaker/Profile";
import OTTDashboard from "./pages/OTT/Dashboard";
import OTTProjects from "./pages/OTT/Projects";
import OTTReady from "./pages/OTT/Ready";
import About from "./pages/About";
import Contact from "./pages/Contact";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import SecurityPolicy from "./pages/SecurityPolicy";
import Disclaimer from "./pages/Disclaimer";
import PaymentsPolicy from "./pages/PaymentsPolicy";
import { Auth } from "./Auth";
import OTTLogin from "./pages/OTTLogin";
import { OTTAuth } from "./OTTAuth";
import Investor from "./pages/Investor";
import Filmmaker from "./pages/Filmmaker";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/investor" element={<Investor />} />
          <Route path="/filmmaker" element={<Filmmaker />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/security-policy" element={<SecurityPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/payments-policy" element={<PaymentsPolicy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/ott-login" element={<OTTLogin />} />

          <Route path="/filmmaker/*" element={<Auth />}>
            <Route path="dashboard" element={<ContentDashboard />} />
            <Route path="projects" element={<Projects />} />
            <Route path="ready" element={<Ready />} />
            <Route path="profile" element={<Profile />} />
          </Route>

          <Route path="/ott/*" element={<OTTAuth />}>
            <Route path="dashboard" element={<OTTDashboard />} />
            <Route path="projects" element={<OTTProjects />} />
            <Route path="ready" element={<OTTReady />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
