import Header from "./Header";
import Footer from "./Footer";

function TermsOfUse() {
  return (
    <>
      <Header />

      <section className="pt-7 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="display-3 fw-bold">Terms of use</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <p className="fs-4 mb-4">
                These Terms and Conditions ("Terms") are entered into between
                Fundoramaa, a private company limited by shares, incorporated
                under the applicable laws, with its registered office located at
                [contact@fundoramaa.com], 1101, 11th Floor, Padmavati Heights,
                Vile Parle East, Mumbai, Maharashtra – 400057 (hereinafter
                referred to as "Fundoramaa," which expression shall, unless the
                context otherwise requires, include its successors and permitted
                assigns), and the users registering on www.fundoramaa.com
                ("Platform/Website").
              </p>

              <p className="fs-4 mb-4">
                The terms “you” and “your” refer to the user of the Platform
              </p>

              <p className="fs-4 mb-4">
                The terms “us” and “our” refer to Fundoramaa.
              </p>

              <p className="fs-4 mb-4">
                Our services and these Terms and Conditions are in compliance
                with the applicable laws and regulations of the relevant
                jurisdictions. By using our Platform, you agree to be bound by
                these Terms and Conditions. Your acceptance of these Terms
                (whether express or implied) also signifies your agreement to
                abide by all policies of Fundoramaa, including, but not limited
                to, our Privacy Policy, as it is in force.
              </p>

              <p className="fs-4 mb-4">
                If you do not agree with these Terms and Conditions, your only
                recourse is to stop using the Platform. By accepting these Terms
                and Conditions, you are granted access to the Platform/Website.
                In the event of a breach of these Terms or other policies you
                have agreed to, Fundoramaa reserves the right to terminate or
                revoke your access to the Platform without notice.
              </p>

              <p className="fs-4 mb-4">
                You acknowledge that you have read, understood, and agreed to
                these Terms and Conditions, as well as our Privacy Policy.
              </p>

              <p className="fs-4 mb-4">
                By submitting your information via our webform, you consent to
                receiving promotional calls at the provided number, including
                calls from third-party platforms.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">General</h3>
              </div>

              <p className="fs-4 mb-4">
                The Platform/Website serves as a facilitator, offering invoice
                discounting services and financial products to users
                ("Services").
              </p>
              <p className="fs-4 mb-4">
                By using the Platform and its Services, you acknowledge and
                accept these Terms and Conditions, as well as any other policies
                of Fundoramaa, which may be updated periodically.
              </p>
              <p className="fs-4 mb-4">
                You agree that Fundoramaa may modify these Terms and Conditions
                at any time by posting the updated terms on the Platform. It is
                your responsibility to regularly review these terms. Your
                continued use of the Platform after any updates indicates your
                acceptance of the revised Terms and Conditions.
              </p>
              <p className="fs-4 mb-4">
                When registering on our Platform, if you choose to use any of
                the Services, you may be required to enter into a separate
                agreement specific to the Service you select. In such cases,
                these Terms and Conditions will be considered as part of those
                specific agreements ("Additional Agreements").
              </p>
              <p className="fs-4 mb-4">
                You acknowledge and agree that Fundoramaa acts solely as a
                facilitator, providing users with invoice discounting and
                financing opportunities. Fundoramaa and its Platform should not
                be interpreted as being involved in credit guarantee, credit
                enhancement, money lending, or loaning activities.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Accounts</h3>
              </div>

              <p className="fs-4 mb-4">
                By creating an account with Fundoramaa, you confirm that you are
                above the age of 18 and that the information you provide is
                accurate, complete, and up to date. Any inaccurate, incomplete,
                or outdated information may lead to the immediate termination of
                your account on the Platform.
              </p>
              <p className="fs-4 mb-4">
                You can access our Services by creating an account on the
                Platform ("Account").
              </p>
              <p className="fs-4 mb-4">
                During the account creation process, you will be asked to
                provide certain information ("User Information"), which may
                include, but is not limited to: Full Name, Username, Email ID,
                PAN Number, Aadhar Number.
              </p>
              <p className="fs-4 mb-4">
                In addition to the information listed above, you may be required
                to provide further details during the account creation process.
                By proceeding, you consent to providing such information to the
                Platform.
              </p>
              <p className="fs-4 mb-4">
                Any individual, company, body corporate, LLP (Limited Liability
                Partnership), partnership firm, HUF (Hindu Undivided Family), or
                trust capable of forming a binding contract under Indian law is
                eligible to register on the Website. The Services are not
                available to minors under the age of eighteen (18) or any users
                who have been suspended or removed from the Platform by
                Fundoramaa for any reason.
              </p>
              <p className="fs-4 mb-4">
                You are responsible for safeguarding the confidentiality of your
                Account and password, including limiting access to your computer
                and account. You agree to take full responsibility for any and
                all actions that occur under your account and/or password,
                whether such activities occur on the Platform or through a
                third-party service. You must notify us immediately if you
                become aware of any security breaches or unauthorized use of
                your account.
              </p>
              <p className="fs-4 mb-4">
                You agree and confirm that the information provided regarding
                your Account is always accurate and up to date.
              </p>
              <p className="fs-4 mb-4">
                We reserve the right, at our sole discretion, to refuse service,
                suspend accounts, modify or remove content, or cancel orders.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Termination and Suspension</h3>
              </div>

              <p className="fs-4 mb-4">
                We reserve the right to terminate or suspend your account and
                deny access to the Platform immediately, without prior notice or
                liability, at our sole discretion, for any reason, including but
                not limited to a breach of the Terms and Conditions.
              </p>
              <p className="fs-4 mb-4">
                If you wish to terminate your account, you may do so by
                discontinuing the use of our Platform. Any provisions of the
                Terms and Conditions that, by their nature, should continue
                after termination will remain in effect, including, but not
                limited to, indemnity and limitations of liability.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Miscellaneous</h3>
              </div>

              <p className="fs-4 mb-4">
                <b>Notices : </b>
                All notices from Fundoramaa will be sent to your account via
                email or posted as a general notification on the Platform. Any
                notice you wish to send to Fundoramaa should be directed to
                support@fundoramaa.com.
              </p>
              <p className="fs-4 mb-4">
                <b>Severability : </b>
                If any provision of these Terms and Conditions is deemed
                invalid, illegal, or unenforceable by a court or other competent
                tribunal, that provision will be removed or modified to the
                minimum extent necessary, ensuring that the remaining provisions
                of the Terms and Conditions remain in full force and effect.
              </p>
              <p className="fs-4 mb-4">
                <b>Waiver : </b>
                No waiver by the User of any term or condition in these Terms
                and Conditions shall be considered a further or continuing
                waiver of that term or condition, or a waiver of any other term
                or condition. Additionally, Fundoramaa’s failure to assert any
                right or provision under these Terms and Conditions shall not be
                interpreted as a waiver of that right or provision.
              </p>
              <p className="fs-4 mb-4">
                <b>Relief : </b>
                In the event of a breach of these Terms, you acknowledge that
                Fundoramaa may suffer irreparable harm and may not have an
                adequate remedy in the form of monetary damages. As a result,
                Fundoramaa shall be entitled to seek an injunction to prevent
                such a breach from any court of competent jurisdiction.
                Fundoramaa’s right to seek this relief shall not limit its
                ability to pursue other remedies.
              </p>
              <p className="fs-4 mb-4">
                <b>Governing Law and Disputes : </b>
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of India, without regard to conflict of
                law principles. Any disputes arising from these Terms and
                Conditions shall be subject to the exclusive jurisdiction of the
                courts in Mumbai, India.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default TermsOfUse;
