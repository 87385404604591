import Header from "./Header";
import Footer from "./Footer";

function Investor() {
  return (
    <>
      <Header />

      <section className="pt-7 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="display-3 fw-bold">Investor</h1>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <img
            src="/assets/images/about-investor.png"
            class="w-100 rounded-5"
          />
        </div>
      </section>

      <section className="py-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row g-3">
                <div className="col-lg-4 col-md-12 col-12">
                  <div className="bg-light mb-4 rounded-4 h-100">
                    <div className="card-body p-6 text-center">
                      <div className="mb-4">
                        <div>
                          <h2 className="fw-bold mb-1">Discover Projects</h2>
                        </div>
                      </div>
                      <p className="mb-0 fs-4">
                        Investors browse through various film projects listed on
                        Fundoramaa.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12">
                  <div className="bg-light mb-4 rounded-4 h-100">
                    <div className="card-body p-6 text-center">
                      <div className="mb-4">
                        <div>
                          <h2 className="fw-bold mb-1">Purchase Invoices</h2>
                        </div>
                      </div>
                      <p className="mb-0 fs-4">
                        They can purchase the invoices at a discounted rate,
                        which allows them to earn a return once the invoices are
                        paid in full by the OTT platforms or other distributors.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12">
                  <div className="bg-light mb-4 rounded-4 h-100">
                    <div className="card-body p-6 text-center">
                      <div className="mb-4">
                        <div>
                          <h2 className="fw-bold mb-1">Revenue Transfer</h2>
                        </div>
                      </div>
                      <p className="mb-0 fs-4">
                        To reduce the risk for investors, Fundoramaa arranges
                        direct revenue transfers from major OTT platforms to the
                        investors, ensuring that they receive their returns with
                        minimal risk of default.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="mt-7">Solution provider</h2>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2>Quality content selection</h2>
                    <p className="fs-4 my-2 pe-lg-8">
                      Quality content selection refers to the process of
                      carefully curating and choosing media, such as films, TV
                      shows, or other creative works, that meet specific
                      standards of excellence. This includes factors like
                      storytelling, production value, originality, audience
                      appeal, and relevance. For platforms like Fundoramaa,
                      focusing on quality content means selecting projects that
                      have strong potential for both creative impact and
                      financial success. This ensures that investors and
                      audiences alike will find value in the content, leading to
                      better engagement and returns.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/investor/6.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/investor/7.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="ps-lg-8">Quality Control</h2>
                    <p className="fs-4 my-2 ps-lg-8">
                      Quality Control For investors, Quality control ensures
                      that the projects they are funding meet high standards,
                      reducing risks and increasing the potential for returns.
                      It involves thorough vetting of a project’s content,
                      financial viability, and market appeal. In the context of
                      Fundoramaa, quality control means verifying that the
                      projects listed on the platform are well-managed, with a
                      strong production plan, solid team, and potential for
                      success, offering investors a higher level of confidence
                      in their investments.
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2>Cost-effectiveness</h2>
                    <p className="fs-4 my-2 pe-lg-8">
                      Cost-effectiveness refers to achieving the desired
                      outcomes or results while minimizing costs. In the context
                      of funding for filmmakers or production houses, it means
                      providing financial support in a way that maximizes the
                      impact of every dollar spent. For platforms like
                      Fundoramaa, cost-effectiveness could involve offering
                      affordable funding options through invoice discounting,
                      ensuring filmmakers and investors get the best value
                      without unnecessary overhead, thus making the funding
                      process more efficient and sustainable.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/investor/2.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/investor/5.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="ps-lg-8">Production Know-How and Delays</h2>
                    <p className="fs-4 my-2 ps-lg-8">
                      Production Know-How and Delays Production know-how refers
                      to the expertise and technical knowledge required to
                      successfully produce a project, from pre-production
                      planning to post-production execution. It includes skills
                      in managing budgets, schedules, crews, and equipment.
                      Delays, on the other hand, occur when production timelines
                      are extended due to unforeseen challenges, such as
                      logistical issues, budget constraints, or creative
                      revisions. For investors, understanding production
                      know-how and managing potential delays is crucial for
                      minimizing risks and ensuring the timely delivery of
                      projects. In platforms like Fundoramaa, clear
                      communication about production timelines and capabilities
                      helps build investor trust.
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="pe-lg-8">Creative Know-How and Delays</h2>
                    <p className="fs-4 my-2 pe-lg-8">
                      Creative Know-How and Delays Creative know-how refers to
                      the expertise and skills required to develop compelling,
                      original, and high-quality content. This includes
                      storytelling, direction, design, and overall artistic
                      vision. Delays in the creative process can arise from
                      challenges such as changes in the script, creative
                      disagreements, or adjustments in artistic direction. For
                      investors, understanding the creative know-how behind a
                      project and the potential for creative delays helps manage
                      expectations and ensures that the final product aligns
                      with both artistic and financial goals. Effective creative
                      management minimizes these delays and enhances the
                      project’s success.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/investor/3.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/investor/4.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="ps-lg-8">OTT channel know-how and Delays</h2>
                    <p className="fs-4 my-2 ps-lg-8">
                      OTT channel know-how and Delays refers to the expertise
                      and understanding required to navigate and succeed in the
                      over-the-top (OTT) streaming platform space. This includes
                      knowledge of content distribution, platform algorithms,
                      audience engagement, and monetization strategies. Delays
                      in the OTT space can occur due to issues like content
                      approval, platform integration, or technical difficulties
                      with streaming. For investors, understanding the OTT
                      channel dynamics and potential delays helps in evaluating
                      the readiness of a project for successful distribution,
                      ensuring it reaches its target audience on time and
                      maximizes its potential for returns.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-8 mb-4">
                <div className="row">
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="pe-lg-8">
                      For multiple genres project solutions
                    </h2>
                    <p className="fs-4 my-2 pe-lg-8">
                      For multiple genres project solutions, it refers to
                      offering tailored funding or support for projects that
                      span across various genres, such as drama, comedy,
                      thriller, and documentary. These solutions cater to the
                      unique needs of each genre, considering factors like
                      audience preferences, production requirements, and
                      distribution strategies. Platforms like Fundoramaa can
                      provide flexible funding options and strategies for
                      projects that blend genres, ensuring each genre's specific
                      challenges are addressed. This approach helps maximize the
                      potential success of multi-genre projects, appealing to
                      diverse audiences and investors.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/investor/1.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Investor;
