import Header from "./Header";
import Footer from "./Footer";

function PaymentsPolicy() {
  return (
    <>
      <Header />

      <section className="pt-7 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="display-3 fw-bold">Payments Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <p className="fs-4 mb-4">
                <ul>
                  <li>
                    Registration on the Fundoramaa Platform/Website is free for
                    all users.
                  </li>
                  <li>
                    Users will only incur charges for the Services they select,
                    in accordance with the terms set out in the Additional
                    Agreements.
                  </li>
                  <li>
                    The pricing for each Service varies depending on the
                    specific Service selected.
                  </li>
                  <li>
                    All prices are inclusive of applicable taxes and levies.
                  </li>
                  <li>
                    The pricing details for the Services provided on the
                    Platform will be specified in the Additional Agreements.
                  </li>
                  <li>
                    As Fundoramaa operates as an alternative investment
                    facilitation platform, refunds and cancellations are
                    generally not applicable to the Services. However, the
                    refund policy for each Service may differ and will be
                    outlined in the respective Additional Agreements.
                  </li>
                </ul>
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Restrictions on Use of Platform</h3>
              </div>

              <p className="fs-4 mb-4">
                Your use of the Fundoramaa Platform and Services is strictly
                limited to personal and non-commercial purposes. Any use of the
                Platform and/or Services for commercial or other purposes is
                prohibited. Your personal, non-commercial use of the Platform
                and Services is subject to the following restrictions:
              </p>

              <p className="fs-4 mb-4">
                <ul>
                  <li>
                    You shall not decompile, reverse engineer, disassemble,
                    modify, copy, distribute, transmit, display, perform,
                    reproduce, publish, license, create derivative works from,
                    transfer, or sell any information or software obtained from
                    the Platform, Website/Application, or Services, nor remove
                    any copyright, trademark, or other proprietary notices from
                    the content on the Platform.
                  </li>
                  <li>
                    You may not use the Platform for any commercial purposes,
                    including advertising, selling, soliciting contributions or
                    donations, or using any public forum for commercial
                    activities. Additionally, you shall not use the Platform in
                    any manner that is unlawful or detrimental to Fundoramaa or
                    any other individual or entity, as determined by Fundoramaa.
                  </li>
                </ul>
              </p>

              <p className="fs-4 mb-4">
                You are prohibited from performing any of the following
                activities while using our Services or Platform:
              </p>

              <p className="fs-4 mb-4">
                <ul>
                  <li>
                    Transmitting material that promotes criminal behavior, leads
                    to civil liability, or violates any applicable laws,
                    regulations, or ethical standards.
                  </li>
                  <li>
                    Altering or modifying any part of the Services or Content.
                  </li>
                  <li>
                    Making available content that is false, illegal, harmful,
                    threatening, abusive, defamatory, obscene, or otherwise
                    inappropriate or offensive.
                  </li>
                  <li>
                    Interfering with other users' enjoyment or use of the
                    Platform.
                  </li>
                  <li>
                    Uploading, transmitting, or storing content that is
                    protected by copyright without obtaining the necessary
                    permission from the copyright owner, or engaging in
                    activities that violate intellectual property rights.
                  </li>
                  <li>
                    Impersonating any person or entity or misrepresenting your
                    affiliation with any person or entity.
                  </li>
                  <li>
                    Posting, transmitting, or making available any content that
                    contains viruses, spyware, trojan horses, time bombs,
                    cancelbots, or other harmful programs that could damage the
                    Platform, infringe on the rights of other users, or disrupt
                    the functionality of any computer or network.
                  </li>
                  <li>
                    Disrupting the security of the Platform, or attempting to
                    access unauthorized user accounts, passwords, or connected
                    servers and networks.
                  </li>
                  <li>
                    Using the Platform in a manner that could damage, disable,
                    overload, or impair its servers, networks, or the
                    functionality of the Platform.{" "}
                  </li>
                  <li>
                    Engaging in any activity that interferes with or disrupts
                    the Services or violates applicable laws.
                  </li>
                  <li>
                    Using deep links, robots, spiders, or other automated
                    methods to monitor, scrape, or extract content from the
                    Platform, or to access information that is not explicitly
                    made available through the Platform.
                  </li>
                  <li>
                    Using the Services for purposes not permitted by these Terms
                    and/or applicable laws.
                  </li>
                </ul>
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Intellectual Property</h3>
              </div>

              <p className="fs-4 mb-4">
                <ul>
                  <li>
                    The Fundoramaa Platform, along with its original content
                    (excluding user-generated content), features, and
                    functionalities, is and will remain the exclusive property
                    of Fundoramaa and its licensors. The Platform is protected
                    by copyright, trademark, and other intellectual property
                    laws, both in India and internationally. Our trademarks may
                    not be used in connection with any product or service
                    without Fundoramaa’s prior written consent.
                  </li>
                  <li>
                    The trademarks, logos, and service marks displayed on the
                    Platform are the exclusive property of Fundoramaa. You are
                    prohibited from using these trademarks, logos, and service
                    marks in any manner without prior written permission from
                    Fundoramaa.
                  </li>
                </ul>
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Grievance Redressal</h3>
              </div>

              <p className="fs-4 mb-4">
                <ul>
                  <li>
                    Fundoramaa has appointed a Grievance Officer to address any
                    concerns related to data security, privacy, or issues
                    related to the use of the Platform. You can reach out to the
                    Grievance Officer through the following contact details:
                  </li>
                  <li>
                    Address: contact@fundoramaa.com 1101, 11th floor , Padmavati
                    Heights, Vile Parle East, Mumbai, Maharashtra - 400057
                  </li>
                </ul>
              </p>

              <p className="fs-4 mb-4 text-primary">Email: support@fundoramaa.com</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default PaymentsPolicy;
