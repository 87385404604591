import Header from "./Header";
import Footer from "./Footer";

function SecurityPolicy() {
  return (
    <>
      <Header />

      <section className="pt-7 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="display-3 fw-bold">Security Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <p className="fs-4 mb-4">
                Fundoramaa’s security policy would likely include guidelines and
                protocols to ensure the safety and protection of user data,
                platform integrity, and secure financial transactions.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Data Protection</h3>
              </div>

              <p className="fs-4 mb-4">
                <b> Encryption : </b> The platform would use encryption (e.g.,
                SSL/TLS) to secure data transmitted between users and the
                platform, ensuring that sensitive information like passwords,
                personal data, and financial details are protected during
                transmission.
              </p>
              <p className="fs-4 mb-4">
                <b>Data Storage : </b> User data, including personal information
                and campaign data, is securely stored in databases with
                restricted access. Data would be protected through encryption
                both at rest and in transit to prevent unauthorized access.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Account Security</h3>
              </div>

              <p className="fs-4 mb-4">
                <b> User Authentication : </b> Strong password policies,
                including requirements for complex passwords, and possibly
                two-factor authentication (2FA) for added security when
                accessing accounts
              </p>
              <p className="fs-4 mb-4">
                <b>Login Monitoring : </b> The platform may monitor login
                activities for unusual behavior and alert users or lock accounts
                if suspicious activity is detected, such as multiple failed
                login attempts.
              </p>
              <p className="fs-4 mb-4">
                <b>User Responsibility : </b> Recommendations for users on
                maintaining the security of their accounts, such as not sharing
                login credentials, using secure passwords, and enabling 2FA if
                available.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default SecurityPolicy;
