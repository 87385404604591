import Header from "./Header";
import Footer from "./Footer";

function PrivacyPolicy() {
  return (
    <>
      <Header />

      <section className="pt-7 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="display-3 fw-bold">Privacy Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <p className="fs-4 mb-4">
                At FUNDORAMAA, we are dedicated to protecting the privacy of
                visitors to our website and mobile application. This Privacy
                Policy outlines how we handle your personal information and
                should be read in conjunction with our Terms and Conditions,
                available at https://fundoramaa.com/privacy_policy.
              </p>
              <p className="fs-4 mb-4">
                This Privacy Policy is an electronic document created in
                compliance with the Information Technology Act, 2000, the
                Consumer Protection (E-Commerce) Rules, 2020, and Rule 3(1) of
                the Information Technology (Intermediary Guidelines and Digital
                Media Ethics Code) Rules, 2021, along with any amendments. Here,
                “you” or “User” refers to the end user accessing the website
                [www.fundoramaa.com] or the web application [Fundoramaa.com]
                (together referred to as the “Fundoramaa Platform”), its
                content, and utilizing the services offered through it
                (“Services”). References to “we,” “us,” “our,” and “Fundoramaa”
                indicate FUNDORAMAA, including its predecessors and successors
                in interest.
              </p>
              <p className="fs-4 mb-4">
                Your trust is essential to us, and we are committed to upholding
                the highest standards for secure communication and the privacy
                of customer information. Please read the following statement to
                understand our practices for gathering and handling information.
                This Privacy Policy may be updated at any time without prior
                notice, so we encourage you to review it periodically to stay
                informed of any changes.
              </p>
              <p className="fs-4 mb-4">
                To serve you better and ensure the authenticity of our Services,
                the Company collects various types of information to identify
                you accurately. This information, collectively referred to as
                “Information,” includes the following details:
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">What do We Collect?</h3>
              </div>

              <p className="fs-4 mb-4">
                We may collect, store, and use the following kinds of personal
                and non-personal data:
              </p>
              <p className="fs-4 mb-4">
                <b>Automatically Collected Information : </b>
                We collect certain information automatically when you visit and
                use the Fundoramaa Platform, which includes details such as your
                IP address, user actions, email address, mailing address,
                educational qualifications, phone number, geographical location,
                browser type, referral source, visit duration, number of page
                views, and other personally identifiable information.
                Additionally, permanent and/or temporary cookies may be stored
                on your device. These small files help us recognize your
                browser, remember your preferences, and track certain details to
                enhance your experience on the Platform. Cookies also allow us
                to gather aggregated, anonymized data about website traffic,
                aiding in future improvements. We may also engage trusted
                third-party services to track this information on our behalf. To
                delete these cookies, please refer to your browser's
                instructions for managing stored cookies.
              </p>
              <p className="fs-4 mb-4">
                <b>Information You Provide to Us : </b>
                Information you provide to us when registering or creating an
                account on the Fundoramaa Platform.
              </p>
              <p className="fs-4 mb-4">
                <b>Transactional Information : </b>
                Details regarding transactions between you and Fundoramaa on or
                through the Fundoramaa Platform, including information about any
                purchases you make of our courses, content, or services.
              </p>
              <p className="fs-4 mb-4">
                <b>Information Required for Services : </b>Information you
                provide to subscribe to our platform services, email
                notifications, and newsletters
              </p>
              <p className="fs-4 mb-4">
                <b>Other Information You Choose to Send to Us : </b> Any
                additional information you choose to share with us voluntarily.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">How We Use Your Personal Data</h3>
              </div>

              <p className="fs-4 mb-4">
                Personal data submitted on our platform will be used as outlined
                in this Privacy Policy or in applicable sections of the
                Application/Website.
              </p>
              <p className="fs-4 mb-4">
                <b>Provide, Troubleshoot, and Improve Fundoramaa Services : </b>
                We use your personal information to enable functionality, assess
                performance, resolve issues, and enhance the usability and
                effectiveness of our services.
              </p>
              <p className="fs-4 mb-4">
                <b>Recommendations and Personalization : </b>We use your
                personal information to suggest features, products, and services
                that may interest you, understand your preferences, and
                personalize your experience with our services.
              </p>
              <p className="fs-4 mb-4">
                <b>Communicate with You : </b> We use your personal information
                to communicate with you regarding our services through various
                channels, such as (phone, email, or chat).
              </p>
              <p className="fs-4 mb-4">
                <b>Advertising : </b> We use your personal information to show
                interest-based ads for features, products, and services that may
                appeal to you. However, we do not use personally identifiable
                information to display these ads.
              </p>
              <p className="fs-4 mb-4">
                We will always give you the option to not provide personal or
                sensitive personal information (as defined under the IT Act,
                2000 and its rules) that we request. Additionally, you can
                withdraw your consent to use such information at any time while
                using the Website/Service. To do so, you must send a written
                request to FUNDORAMAA at the following address: [1101, 11th
                floor , Padmavati Heights, Vile Parle East, Mumbai, Maharashtra
                - 400057]. Please note that if you withdraw your consent,
                Fundoramaa reserves the right to restrict further use of the
                Website or provision of services/products to you.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Sharing of Information</h3>
              </div>

              <p className="fs-4 mb-4">
                Services provided through our platform may be delivered by
                Fundoramaa or by its vendors, dealers, channel partners, and
                other third-party entities ("Other Entities") registered with
                Fundoramaa to offer products or services. You acknowledge and
                agree that Fundoramaa may share, disclose, transfer, or provide
                your information to these Other Entities based on the type of
                product or service you seek, or for other permissible purposes.
                Fundoramaa may also share your personal or non-personal
                information with these entities when required to provide you
                with a product or service, and you give your unconditional
                consent for Fundoramaa to do so.
              </p>
              <p className="fs-4 mb-4">
                Fundoramaa may share statistical data and/or other non-personal
                information to support various programs or initiatives launched
                by Fundoramaa, third-party service providers, partners, or
                financial institutions.
              </p>
              <p className="fs-4 mb-4">
                We use third-party service providers, such as credit/debit card
                processors, payment gateways, and prepaid card providers, to
                facilitate payments for products or services on the platform.
                When you opt for these services, you may have the option to save
                your card details for quicker future payments. In such cases, we
                may share your relevant information, such as your name, address,
                and email, with these third parties as necessary to provide the
                service.
              </p>
              <p className="fs-4 mb-4">
                The information we collect may be shared with government or
                semi-government bodies, registrars, transfer agents, collecting
                banks, KYC registration agencies (KRAs), custodians, and
                depositories, solely for the purpose of processing your
                transaction requests or enhancing our services to you.
                Additionally, Fundoramaa may share your information with
                governmental and judicial authorities (e.g., police,
                investigating agencies like CBI, EOW, etc.), or other law
                enforcement agencies (LEAs) as required by law for identity
                verification or for the prevention, detection, investigation
                (including cyber incidents), prosecution, and punishment of
                offenses.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">
                  Data Security and Protection Measures
                </h3>
              </div>

              <p className="fs-4 mb-4">
                Fundoramaa implements security measures as required by the
                Information Technology (Reasonable Security Practices and
                Procedures and Sensitive Personal Data or Information) Rules,
                2011. In addition to these requirements, Fundoramaa has the
                following security measures in place:
              </p>
              <p className="fs-4 mb-4">
                The Fundoramaa Website have stringent security measures to
                protect against the loss, misuse, and alteration of the
                information under our control. Whenever you change or access
                your account information, we offer the use of a secure server.
                Once your information is in our possession, we adhere to strict
                security guidelines to protect it against unauthorized access.
              </p>
              <p className="fs-4 mb-4">
                Your information will primarily be stored electronically,
                although some data may also be kept in physical form. We may
                store, collect, process, and use your data in countries outside
                the Republic of India, always in compliance with applicable
                laws.
              </p>
              <p className="fs-4 mb-4">
                We may enter into agreements with third parties (in or outside
                of India) to store or process your information or data. These
                third parties may have their own security standards to safeguard
                your information, and we will, on a commercially reasonable
                basis, require such third parties to adopt reasonable security
                standards to protect your information/data.
              </p>
              <p className="fs-4 mb-4">
                Notwithstanding anything in this Privacy Policy or elsewhere,
                Fundoramaa will not be held liable for any loss, damage, or
                misuse of user information or other data shared with us if such
                loss or damage is caused by Force Majeure events. You
                acknowledge that any information, including personal data,
                transmitted to us or shared through your use of the Website is
                done at your own risk.
              </p>
              <p className="fs-4 mb-4">
                Force Majeure Events: This includes any event beyond
                Fundoramaa's reasonable control, such as but not limited to
                sabotage, fire, flood, explosion, natural disasters, civil
                unrest, strikes or industrial actions, riots, insurrection, war,
                government actions, hacking, unauthorized access to computer
                systems or networks, system crashes, breaches of security and
                encryption, and similar events.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Other Disclosures</h3>
              </div>

              <p className="fs-4 mb-4">
                In addition to the disclosures necessary for the purposes
                outlined elsewhere in this Privacy Policy, Fundoramaa may
                disclose information about you in the following circumstances:
              </p>
              <p className="fs-4 mb-4">To the extent required by law.</p>
              <p className="fs-4 mb-4">
                In connection with any ongoing or potential legal proceedings.
              </p>
              <p className="fs-4 mb-4">
                To establish, exercise, or defend our legal rights, including
                providing information to others for fraud prevention and
                mitigating credit risk.
              </p>
              <p className="fs-4 mb-4">
                To a potential or actual purchaser of any business or asset that
                we are selling or considering selling.
              </p>
              <p className="fs-4 mb-4">
                Except as provided in this privacy policy, we will not provide
                your information to third parties.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Sale or Merger of the Company</h3>
              </div>

              <p className="fs-4 mb-4">
                The information collected by Fundoramaa is considered a trade
                secret of the company. As the owner of this trade secret,
                Fundoramaa reserves the right to disclose or sell this
                information as part of the company's assets in the event of a
                sale or merger.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Modifications to the Policy</h3>
              </div>

              <p className="fs-4 mb-4">
                If we make any changes to our privacy policy, we will update
                this page to keep you informed about the information we collect,
                how we use it, and the circumstances under which we may disclose
                it. However, any significant changes to the policy will be
                communicated to you through our website or via the email address
                you provided on the Fundoramaa Website.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Links to Third-Party Websites</h3>
              </div>

              <p className="fs-4 mb-4">
                The Fundoramaa Website and may include links to third-party
                websites. Your use of and activity on these external sites is at
                your own risk, and Fundoramaa is not liable for any information
                you provide on those websites.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Disclaimer as to Loss</h3>
              </div>

              <p className="fs-4 mb-4">
                While Fundoramaa takes appropriate measures to protect your data
                in accordance with legal standards, we shall not be held liable
                for any unauthorized leakage of data. By using the website
                and/or creating an account, you acknowledge and agree to this
                disclaimer.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Authorization</h3>
              </div>

              <p className="fs-4 mb-4">
                In accordance with the guidelines set by the Telecom Regulatory
                Authority of India ("TRAI"), you hereby authorize Fundoramaa,
                its affiliates/partners, or any other entities accessing your
                information through their association with Fundoramaa, to
                communicate with you via WhatsApp, phone, email, SMS, or any
                other communication methods currently available or that may
                become available in the future.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">
                  Rights to Control Your Personal Information
                </h3>
              </div>

              <p className="fs-4 mb-4">
                Rectify Your Information: We make every reasonable effort to
                keep our records up to date with the information you provide. If
                you notice any inaccuracies in your personal information, you
                can edit certain details yourself or contact our Data Protection
                Officer/Grievance Officer to have it corrected.
              </p>
              <p className="fs-4 mb-4">
                Object to, Limit, or Restrict Use: You have the right to request
                that we stop or limit (either partially or fully) the use of
                your personal information, whether it pertains to past, present,
                or future data.
              </p>
              <p className="fs-4 mb-4">
                Withdraw Consent/Opt-Out: You have the right to withdraw any
                consent you have provided to us at any time by sending an email
                to support@fundoramaa.com.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Intellectual Property</h3>
              </div>

              <p className="fs-4 mb-4">
                The Fundoramaa Platform, along with its original content
                (excluding user-provided content), features, and functionality,
                is and will remain the exclusive property of Fundoramaa and its
                licensors. The platform is protected by copyright, trademark,
                and other relevant laws. Our trademarks may not be used in
                connection with any product or service without prior written
                consent from Fundoramaa.
              </p>
              <p className="fs-4 mb-4">
                The trademarks, logos, and service marks displayed on the
                platform are owned by Fundoramaa. You are prohibited from using
                these intellectual properties in any manner without explicit
                authorization.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Contact Information</h3>
              </div>

              <p className="fs-4 mb-4">
                Our Grievance Officer will make all reasonable efforts to
                address your concerns as quickly as possible. You can contact us
                at:
              </p>
              <p className="fs-4 mb-4">
                Grievance Officer (in accordance with the IT Act, 2000 and its
                rules): support@fundoramaa.com
              </p>
              <p className="fs-4 mb-4">
                Customer Support: For any queries, please reach out to us at
                support@fundoramaa.com.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Risk Disclosure</h3>
              </div>

              <p className="fs-4 mb-4">
                Fundoramaa emphasizes that participating in our Invoice
                Discounting Platform carries significant risk. By using the
                Fundoramaa platform, you consent that, before making any
                decision to participate, you have carefully evaluated the
                associated risks, your objectives, and your financial capacity.
                Before proceeding, you acknowledge and agree to the following:
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Loss of Purchased Value</h3>
              </div>

              <p className="fs-4 mb-4">
                You agree that participating in any invoice discounting
                opportunities listed on our platform does not guarantee returns,
                regardless of the amount you purchase. You understand and
                acknowledge that, as a purchaser, your involvement carries
                risks, and you may lose all or part of your invested amount.
                Additionally, you recognize that participation in invoice
                discounting involves inherent risks, and you have assessed these
                risks before deciding to participate. Fundoramaa and its
                affiliates do not make any representations, warranties, or
                guarantees regarding the full value of your participation in any
                invoice discounting opportunity.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Adverse Market Conditions</h3>
              </div>

              <p className="fs-4 mb-4">
                Fundoramaa may provide certain forward-looking statements about
                the invoice discounting opportunities listed on the platform.
                These statements are based on past performance, which may not
                necessarily predict future results due to the changing economic
                landscape. You acknowledge that relying on such statements does
                not guarantee any specific outcome or performance from your
                participation in a particular invoice discounting opportunity.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Leverage</h3>
              </div>

              <p className="fs-4 mb-4">
                Participation in the opportunities currently does not offer any
                avenues for leverage.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Lack of Liquidity</h3>
              </div>

              <p className="fs-4 mb-4">
                Participation in invoice discounting opportunities on our
                platform should be viewed as a long-term investment. Due to
                market conditions, liquidity at the projected price may not
                always be achievable. You acknowledge that liquidity cannot be
                guaranteed, and this opportunity may not be suitable for those
                who require immediate liquidity. Do not participate if you are
                unable to hold your investment indefinitely.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Natural or Man-Made Disasters</h3>
              </div>

              <p className="fs-4 mb-4">
                The occurrence of natural or man-made disasters, such as
                hurricanes, floods, earthquakes, fires, pandemics, acts of
                terrorism, or military actions, could negatively impact the
                Indian economy and potentially affect the performance of an
                invoice discounting opportunity.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Delayed Payments</h3>
              </div>

              <p className="fs-4 mb-4">
                You acknowledge that payments related to an invoice discounting
                opportunity may experience reasonable or extended delays. You
                agree that payments may be received later than the agreed-upon
                date, and Fundoramaa is not responsible for ensuring timely
                payouts associated with the invoice discounting opportunity.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Inaccurate/Incomplete Information</h3>
              </div>

              <p className="fs-4 mb-4">
                You acknowledge that the information provided by Fundoramaa
                regarding an invoice discounting opportunity may not be
                complete, including any reports and analyses. You are solely
                responsible for thoroughly reviewing each opportunity before
                deciding to participate. Additionally, you understand that
                Fundoramaa may not be able to provide comprehensive details for
                every invoice discounting opportunity listed on the platform.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Changes in Indian Law/Regulations</h3>
              </div>

              <p className="fs-4 mb-4">
                You acknowledge that the legal and regulatory environment
                surrounding invoice discounting opportunities on our platform is
                continuously evolving. Changes in laws or regulations may
                negatively impact the performance of these opportunities and
                could affect your participation in a specific opportunity.
              </p>
              <p className="fs-4 mb-4">
                The risk factors outlined above do not represent an exhaustive
                list of risks associated with invoice discounting. Potential
                purchasers are encouraged to read this document, as well as the
                relevant opportunity documents, in full before making any
                decision to participate. Fundoramaa acts solely as a facilitator
                for the services offered on our platform and does not guarantee
                returns on your participation.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
