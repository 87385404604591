import Header from "./Header";
import Footer from "./Footer";

function CookiePolicy() {
  return (
    <>
      <Header />

      <section className="pt-7 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="display-3 fw-bold">Cookie Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <p className="fs-4 mb-4">
                Cookies are small text files stored on your device (computer,
                smartphone, or other electronic devices) when you visit a
                website. They help websites remember your preferences, enhance
                user experience, and collect analytical data to improve
                services.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">How We Use Cookies</h3>
              </div>

              <p className="fs-4 mb-4">
                Fundoramaa uses cookies for the following purposes:
              </p>
              <p className="fs-4 mb-4">
                <b>Essential Cookies : </b> These cookies are necessary for the
                website to function properly. They allow you to navigate our
                site and use its features, such as accessing secure areas.
              </p>
              <p className="fs-4 mb-4">
                <b>Performance and Analytics Cookies : </b> These cookies help
                us understand how visitors interact with our website by
                collecting anonymous data, such as pages visited and time spent
                on the site. This information helps us improve our platform.
              </p>
              <p className="fs-4 mb-4">
                <b>Functional Cookies : </b> Functional cookies remember your
                preferences, such as language or region, to enhance your
                browsing experience.
              </p>
              <p className="fs-4 mb-4">
                <b>Advertising and Targeting Cookies : </b> We may use these
                cookies to deliver relevant advertisements and track the
                effectiveness of marketing campaigns.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Third-Party Cookies</h3>
              </div>

              <p className="fs-4 mb-4">
                We may use third-party services that place cookies on your
                device. These cookies help analyze website traffic and provide
                tailored content. For example:
              </p>

              <p className="fs-4 mb-4">
                <b>Google Analytics : </b> To track and report website usage.
              </p>
              <p className="fs-4 mb-4">
                <b>Social Media Platforms : </b> To enable sharing and
                engagement with our content.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Your Choices Regarding Cookies</h3>
              </div>

              <p className="fs-4 mb-4">
                You can control and manage cookies in several ways:
              </p>
              <p className="fs-4 mb-4">
                <b>Browser Settings : </b>Most web browsers allow you to manage
                cookies through their settings. You can block or delete cookies.
              </p>
              <p className="fs-4 mb-4">
                <b>Cookie Consent Tool : </b> On your first visit to our
                website, you’ll see a banner allowing you to accept or customize
                cookie settings.
              </p>
              <p className="fs-4 mb-4">
                Please note that disabling some cookies may affect your ability
                to use certain features of our website.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Updates to This Policy</h3>
              </div>

              <p className="fs-4 mb-4">
                We may update this Cookies Policy from time to time to reflect
                changes in our practices or for legal, regulatory, or
                operational reasons. Please review this policy periodically.
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Contact Us</h3>
              </div>

              <p className="fs-4 mb-4">
                If you have any questions or concerns about our use of cookies,
                please contact us at : [support@fundoramaa.com]
              </p>

              <p className="fs-4 mb-4">
                Address: [1101, 11th floor , Padmavati Heights, Vile Parle East,
                Mumbai, Maharashtra - 400057]
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default CookiePolicy;
