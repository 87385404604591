import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function OTTLogin() {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("ottToken");
    if (token) {
      navigate("/ott/dashboard");
    }
  }, [navigate]);

  // Function to verify OTP
  const handleOttLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://ott-ep3g.onrender.com/api/v1/ott/login/",
        {
          id: id,
          password: password,
        }
      );

      if (response.data && response.data.message) {
        const { token } = response.data;
        localStorage.setItem("ottToken", token);
        navigate("/ott/dashboard");
      } else {
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while verifying OTP. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-bg vh-lg-100 vw-lg-100 d-flex align-items-center">
        <div className="container h-100d-flex align-items-center py-lg-0 py-8">
          <div className="row g-3">
            <div className="col-lg-5 offset-lg-7 col-md-7 col-12">
              <div className="p-5 rounded-4 bg-white h-100">
                <div className="m-5">
                  <h2 className="lh-1">OTT Login</h2>
                  <p className="mb-5 lh-1">
                    Please enter the ID & Password to complete your login
                    process.
                  </p>
                  <div className="mt-4">
                    <label>
                      ID{" "}
                      <i className="bi bi-info-circle-fill text-secondary ms-1"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control mt-1"
                      placeholder="..."
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    />
                  </div>
                  <div className="mt-4">
                    <label>Password</label>
                    <input
                      type="text"
                      className="form-control mt-1"
                      placeholder="..."
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <button
                    onClick={handleOttLogin}
                    class="btn btn-primary btn-lg mt-4 w-100 rounded-3 fs-5"
                    disabled={loading}
                  >
                    {loading ? (
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      "Continue"
                    )}
                  </button>

                  <div className="mt-3 px-6">
                    <p className="mb-0 text-center">
                      By continuing you accept the{" "}
                      <Link
                        to="/terms-of-use"
                        className="text-inherit fw-semibold"
                      >
                        <u>T&C Apply</u>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OTTLogin;
