import Header from "./Header";
import Footer from "./Footer";

function Filmmaker() {
  return (
    <>
      <Header />

      <section className="pt-7 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="display-3 fw-bold">Filmmaker</h1>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <img
            src="/assets/images/about-filmmaker.png"
            class="w-100 rounded-5"
          />
        </div>
      </section>

      <section className="py-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row g-3">
                <div className="col-lg-4 col-md-12 col-12">
                  <div className="bg-light mb-4 rounded-4 h-100">
                    <div className="card-body p-6 text-center">
                      <div className="mb-4">
                        <div>
                          <h2 className="fw-bold mb-1">Project Listing</h2>
                        </div>
                      </div>
                      <p className="mb-0 fs-4">
                        Filmmakers create a compelling project profile on
                        Fundoramaa, detailing their vision and the financial
                        needs of their project.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12">
                  <div className="bg-light mb-4 rounded-4 h-100">
                    <div className="card-body p-6 text-center">
                      <div className="mb-4">
                        <div>
                          <h2 className="fw-bold mb-1">Invoice Listing</h2>
                        </div>
                      </div>
                      <p className="mb-0 fs-4">
                        Production houses can list their invoices (which
                        represent pending payments from distributors or OTT
                        platforms) on the platform.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12">
                  <div className="bg-light mb-4 rounded-4 h-100">
                    <div className="card-body p-6 text-center">
                      <div className="mb-4">
                        <div>
                          <h2 className="fw-bold mb-1">Funding</h2>
                        </div>
                      </div>
                      <p className="mb-0 fs-4">
                        Investors review these invoices and purchase them at a
                        discounted price, providing filmmakers with immediate
                        funds for their projects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="mt-7">Solution provider</h2>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/10.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="ps-lg-8">Supporting pre-to-post</h2>
                    <p className="fs-4 my-2 ps-lg-8">
                      Supporting pre-to-post production involves providing
                      resources and services across the entire filmmaking
                      lifecycle:
                    </p>
                    <ul className="ps-lg-8 ms-3">
                      <li>
                        Pre-Production: - Assist with funding, planning, script
                        development, budgeting, and securing locations, cast,
                        and crew.
                      </li>
                      <li>
                        Production:- Offer financial support during shooting,
                        including equipment rentals and on-set costs.
                      </li>
                      <li>
                        Post-Production: - Facilitate funding for editing, sound
                        design, visual effects, and marketing/distribution
                        preparation.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2>Film branding</h2>
                    <p className="fs-4 my-2 pe-lg-8">
                      Film branding Involves creating a unique identity for a
                      film to attract audiences and build recognition. This
                      includes developing a logo, tagline, promotional materials
                      (posters, trailers, social media content), and merchandise
                      that resonate with the film's theme and target audience.
                      Effective branding helps establish a memorable image and
                      drives anticipation and engagement before and after
                      release.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/1.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/9.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="ps-lg-8">Script-writing</h2>
                    <p className="fs-4 my-2 ps-lg-8">
                      Script-writing is the process of crafting a screenplay
                      that outlines the story, characters, dialogue, and actions
                      for a film or show. It serves as the blueprint for the
                      production, combining narrative structure, emotional arcs,
                      and visual elements to bring the writer's vision to life
                      on screen.
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2>Quality control</h2>
                    <p className="fs-4 my-2 pe-lg-8">
                      Quality control (QC) In filmmaking ensures the final
                      product meets technical and creative standards. It
                      involves checking video and audio quality, verifying
                      consistency in editing, reviewing for continuity errors,
                      and ensuring compliance with distribution platform
                      specifications. QC guarantees a polished, professional
                      result for audiences.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/2.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/8.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="ps-lg-8">We assist in talent acquisition</h2>
                    <p className="fs-4 my-2 ps-lg-8">
                      We assist in talent acquisition by connecting filmmakers
                      with skilled professionals, including actors, directors,
                      writers, and crew members. Our platform simplifies finding
                      the right talent to match the creative and technical needs
                      of your project, ensuring quality and efficiency in
                      production.
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="pe-lg-8">
                      Fundoramaa supports getting projects approved by OTT
                      platforms and channels
                    </h2>
                    <p className="fs-4 my-2 pe-lg-8">
                      Fundoramaa supports getting projects approved by OTT
                      platforms and channels by bridging the funding gap and
                      providing industry insights. We help creators meet
                      production standards, present polished pitches, and secure
                      financial backing, increasing the likelihood of approval
                      and distribution.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/3.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/5.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="ps-lg-8">
                      We help reduce high production costs
                    </h2>
                    <p className="fs-4 my-2 ps-lg-8">
                      We help reduce high production costs by offering
                      innovative funding solutions, optimizing resource
                      allocation, and connecting filmmakers with cost-effective
                      services and talent. This ensures quality production while
                      staying within budget.
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-8">
                <div className="row">
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="pe-lg-8">Revenue diversification</h2>
                    <p className="fs-4 my-2 pe-lg-8">
                      Revenue diversification involves creating multiple income
                      streams for a project, such as OTT licensing, merchandise,
                      international distribution, sponsorships, and ancillary
                      rights. This strategy ensures financial stability and
                      maximizes profitability for filmmakers.
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/7.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-8 mb-4">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="/assets/images/filmmaker/6.png"
                      className="w-100 rounded-4"
                    />
                  </div>
                  <div className="col-lg-7 col-12 d-flex justify-content-center flex-column">
                    <h2 className="ps-lg-8">Cash flow management</h2>
                    <p className="fs-4 my-2 ps-lg-8">
                      Cash flow management ensures filmmakers have steady access
                      to funds throughout the production cycle. By optimizing
                      expenses, securing timely funding, and bridging financial
                      gaps, we help maintain project momentum and avoid delays.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Filmmaker;
