import Footer from "./Footer";
import Header from "./Header";

function Contact() {
  return (
    <>
      <Header />

      <section class="py-6 bg-white">
        <div class="container mb-lg-6 mt-lg-3">
          <form class="needs-validation">
            <div className="row g-5">
              <div class="col-lg-7 col-12">
                <div class="mb-8">
                  <h1 class="display-4 mb-3 fw-bold">Get in Touch</h1>
                  <p class="mb-0 lead">
                    Feel free to reach out—we'd love to hear from you and answer
                    any questions you may have!
                  </p>
                </div>
                <div>
                  <div class="row">
                    <div class="mb-3 col-12 col-md-6">
                      <label class="form-label" for="fname">
                        First Name<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="fname"
                        class="form-control"
                        placeholder="First Name"
                      />
                      <div class="invalid-feedback">
                        Please enter first name.
                      </div>
                    </div>
                    <div class="mb-3 col-12 col-md-6">
                      <label class="form-label" for="lname">
                        Last Name<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="lname"
                        class="form-control"
                        placeholder="Last Name"
                      />
                      <div class="invalid-feedback">
                        Please enter last name.
                      </div>
                    </div>
                    <div class="mb-3 col-12 col-md-12">
                      <label class="form-label" for="email">
                        Email<span class="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        class="form-control"
                        placeholder="Write you Email id"
                      />
                    </div>
                    <div class="mb-3 col-12 col-md-12">
                      <label class="form-label" for="phone">
                        Phone Number<span class="text-danger">*</span>
                      </label>
                      <div class="input-group mb-2">
                        <input
                          type="text"
                          id="phone"
                          class="form-control"
                          placeholder="Phone"
                        />
                        <select class="form-select" id="inputGroupSelect02">
                          <option value="">Home</option>
                          <option value="1">Work</option>
                          <option value="2">Mobile</option>
                        </select>
                        <div class="invalid-feedback">
                          Please enter phone number.
                        </div>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="formNumber"
                          value=""
                        />
                        <label class="form-check-label" for="formNumber">
                          Send me important updates in this number.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-12 col-md-12 mb-3">
                      <label class="form-label">Your message</label>
                      <textarea
                        class="form-control"
                        rows="4"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div class="mb-5 col-12 col-md-12">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          value=""
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          I accept the terms for submitting this form.
                        </label>
                      </div>
                    </div>
                    <div class="col-12 col-md-12">
                      <button type="submit" class="btn btn-primary px-6">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-12">
                <img
                  src="/assets/images/contact.jpg"
                  className="w-100 object-fit-cover h-100 rounded-4"
                />
              </div>
            </div>
          </form>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Contact;
