import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const OTTAuth = () => {
  if (!localStorage.getItem("ottToken")) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
