import Header from "./Header";
import Footer from "./Footer";

function Disclaimer() {
  return (
    <>
      <Header />

      <section className="pt-7 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="display-3 fw-bold">Disclaimer</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <p className="fs-4 mb-4">
                The Platform is not a stock exchange and does not seek
                recognition as such under the Securities Contract (Regulation)
                Act, 1956 by the Securities and Exchange Board of India (SEBI),
                nor is it authorized by SEBI to solicit investments. The
                securities and investment opportunities facilitated through the
                Platform are not traded on any SEBI-recognized stock exchange.
              </p>

              <p className="fs-4 mb-4">
                All the transactions facilitated on the website are in
                compliance with applicable laws including :
              </p>

              <p className="fs-4 mb-4">
                <ul>
                  <li>
                    The Companies Act, 2013, along with the Rules framed
                    thereunder, specifically Section 42 of the Act.
                  </li>
                  <li>
                    The Foreign Exchange Management Act, 1999, including the
                    Rules, Regulations, Directions, and Circulars framed or
                    issued thereunder;
                  </li>
                  <li>
                    Reserve Bank of India Act, 1934 and Directions, Circulars
                    framed/issued thereunder;
                  </li>
                  <li>
                    The Securities Contracts (Regulation) Act, 1956, along with
                    the Rules and Regulations framed or issued thereunder.
                  </li>
                  <li>
                    Securities Exchange Board of India, 1992 and Rules,
                    Regulations, Directions etc. framed thereunder.
                  </li>
                  <li>
                    The Services provided by Fundoramaa are offered on an "as
                    is" and "as available" basis. Fundoramaa makes no
                    representations or warranties, express or implied, regarding
                    the operation of the Services or the information, content,
                    or materials contained within them. By using these Services,
                    you expressly agree that any use is at your own risk.
                  </li>
                  <li>
                    Neither Fundoramaa nor any person associated with the
                    Fundoramaa Platform makes any warranty or representation
                    regarding the completeness, security, reliability, quality,
                    accuracy, or availability of the Services. Specifically,
                    Fundoramaa does not guarantee that the services will be
                    accurate, reliable, error-free, or uninterrupted, that
                    defects will be corrected, that the platform or server
                    hosting the services is free of viruses or other harmful
                    components, or that the services will meet your specific
                    needs or expectations.
                  </li>
                  <li>
                    Fundoramaa expressly disclaims all warranties of any kind,
                    whether express, implied, statutory, or otherwise, including
                    but not limited to warranties of merchantability,
                    non-infringement, and fitness for a particular purpose.
                  </li>
                  <li>
                    The foregoing does not affect any warranties which cannot be
                    excluded or limited under applicable law.
                  </li>
                  <li>
                    While Fundoramaa makes reasonable efforts to conduct due
                    diligence on the registered users of the website, it does
                    not warrant or guarantee the accuracy or truthfulness of all
                    the information provided on this site. The details of the
                    users, including their financial status, available on or
                    through this platform are provided without any warranties,
                    express or implied.
                  </li>
                </ul>
              </p>

              <div className="mt-5">
                <h3 className="fw-bold">Limitation of Liability</h3>
              </div>

              <p className="fs-4 mb-4">
                <ul>
                  <li>
                    We do not accept any liability for any loss or damage,
                    whether direct or indirect, resulting from any inaccuracies
                    or incompleteness of information, or any breach of warranty
                    or guarantee caused by the actions of any User of the
                    Platform.
                  </li>
                  <li>
                    The Platform and Services are provided on an "as is" and "as
                    available" basis, without any express or implied warranties,
                    unless otherwise stated in writing. We do not guarantee the
                    quality of the Services or the Platform, including but not
                    limited to its uninterrupted, timely, secure, or error-free
                    performance, continued compatibility with any device, or the
                    correction of any errors.
                  </li>
                  <li>
                    Under no circumstances shall Fundoramaa, or any of our
                    affiliates, successors, assigns, or their respective
                    investors, directors, officers, employees, agents, service
                    providers, and suppliers, be liable for any special,
                    incidental, punitive, direct, indirect, or consequential
                    damages resulting from a breach of these Terms by another
                    user, or arising from the use of or reliance on any Services
                    or the Platform.
                  </li>
                  <li>
                    If any exclusion in these Terms is found to be invalid for
                    any reason, and Fundoramaa or any of our affiliated
                    entities, officers, directors, or employees becomes liable
                    for loss or damage, then our liability will be limited to
                    the amounts paid by you for use of the Platform or Services
                    in the month immediately preceding the claim.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Disclaimer;
