import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function Home() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch projects from the API when the component loads
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://ott-ep3g.onrender.com/api/v1/projects/public"
        );
        if (response.data && Array.isArray(response.data.data)) {
          setProjects(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
          setProjects([]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setProjects([]);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  const images = [
    "/assets/images/ott/1.jpg",
    "/assets/images/ott/2.jpg",
    "/assets/images/ott/3.png",
    "/assets/images/ott/4.png",
    "/assets/images/ott/5.jpg",
    "/assets/images/ott/6.png",
    "/assets/images/ott/7.jpg",
    "/assets/images/ott/8.jpg",
    "/assets/images/ott/9.jpg",
    "/assets/images/ott/10.jpg",
    "/assets/images/ott/11.jpg",
    "/assets/images/ott/12.jpg",
    "/assets/images/ott/13.jpg",
    "/assets/images/ott/14.png",
    "/assets/images/ott/1.jpg",
    "/assets/images/ott/2.jpg",
    "/assets/images/ott/3.png",
    "/assets/images/ott/4.png",
    "/assets/images/ott/5.jpg",
    "/assets/images/ott/6.png",
    "/assets/images/ott/7.jpg",
    "/assets/images/ott/8.jpg",
    "/assets/images/ott/9.jpg",
    "/assets/images/ott/10.jpg",
    "/assets/images/ott/11.jpg",
    "/assets/images/ott/12.jpg",
    "/assets/images/ott/13.jpg",
    "/assets/images/ott/14.png",
  ];

  const [investmentAmount, setInvestmentAmount] = useState(2000000);
  const [tenure, setTenure] = useState(1);
  const [wouldBecome, setWouldBecome] = useState(0);

  const calculateReturn = (amount, years) => {
    const rate = 0.16;
    return amount * Math.pow(1 + rate, years);
  };

  useEffect(() => {
    setWouldBecome(calculateReturn(investmentAmount, tenure));
  }, [investmentAmount, tenure]);

  const handleInvestmentChange = (e) => {
    const value = e.target.value.replace(/,/g, "");

    if (/^\d{0,10}$/.test(value)) {
      const numericValue = parseFloat(value) || 0;
      setInvestmentAmount(numericValue);
      setWouldBecome(calculateReturn(numericValue, tenure));
    }
  };

  const handleTenureChange = (years) => {
    setTenure(years);
    setWouldBecome(calculateReturn(investmentAmount, years));
  };

  return (
    <>
      <Header />

      <div className="container-fluid d-lg-block d-none">
        <div
          id="heroBanner"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner rounded-4">
            <div class="carousel-item active rounded-4">
              <img
                src="/assets/images/banner/1.jpg"
                className="w-100 rounded-4"
              />
            </div>
            <div class="carousel-item rounded-4">
              <img
                src="/assets/images/banner/2.jpg"
                className="w-100 rounded-4"
              />
            </div>
            <div class="carousel-item rounded-4">
              <img
                src="/assets/images/banner/3.png"
                className="w-100 rounded-4"
              />
            </div>
            <div class="carousel-item rounded-4">
              <img
                src="/assets/images/banner/4.png"
                className="w-100 rounded-4"
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#heroBanner"
            data-bs-slide="prev"
          >
            <div className="button-md-circle">
              <i className="bi bi-chevron-left display-5 text-dark"></i>
            </div>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#heroBanner"
            data-bs-slide="next"
          >
            <div className="button-md-circle">
              <i className="bi bi-chevron-right display-5 text-dark"></i>
            </div>
          </button>
        </div>
      </div>

      <div className="container-fluid d-lg-none d-block">
        <div
          id="heroMobileBanner"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner rounded-4">
            <div class="carousel-item active rounded-4">
              <img
                src="/assets/images/banner/m1.jpg"
                className="w-100 rounded-4"
              />
            </div>
            <div class="carousel-item rounded-4">
              <img
                src="/assets/images/banner/m2.jpg"
                className="w-100 rounded-4"
              />
            </div>
            <div class="carousel-item rounded-4">
              <img
                src="/assets/images/banner/m3.jpg"
                className="w-100 rounded-4"
              />
            </div>
            <div class="carousel-item rounded-4">
              <img
                src="/assets/images/banner/m4.jpg"
                className="w-100 rounded-4"
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#heroMobileBanner"
            data-bs-slide="prev"
          >
            <div className="button-md-circle">
              <i className="bi bi-chevron-left display-5 text-dark"></i>
            </div>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#heroMobileBanner"
            data-bs-slide="next"
          >
            <div className="button-md-circle">
              <i className="bi bi-chevron-right display-5 text-dark"></i>
            </div>
          </button>
        </div>
      </div>

      <section className="pt-5 pb-5">
        <div className="container my-lg-5">
          <div className="row mb-6 justify-content-center pt-2">
            <div className="col-lg-8 col-md-12 col-12 text-center">
              <h2 className="mb-2 display-4 fw-bold">
                Why{" "}
                <u class="text-primary">
                  <span class="text-dark">Fundoramaa?</span>
                </u>
              </h2>
              <p className="lead">Tailored Financing for funding</p>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-4 col-md-12 col-12">
              <div className="bg-light mb-4 rounded-4 h-100">
                <div className="card-body p-6 text-center">
                  <div className="mb-4">
                    <div className="mb-3 mb-md-0">
                      <img
                        src="/assets/images/investor.png"
                        alt="icon"
                        className="bg-primary icon-shape icon-xxl rounded-circle"
                      />
                    </div>
                    <div>
                      <h2 className="fw-bold mb-1">Investor</h2>
                    </div>
                  </div>
                  <p className="mb-4 fs-4">
                    Fundoramaa stands out for investors by providing curated
                    film projects, personalized investment strategies.
                  </p>
                  <Link
                    to="/investor"
                    class="btn btn-outline-dark rounded-3 px-5"
                  >
                    See more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="bg-1 mb-4 rounded-4 h-100 py-5 d-flex align-items-center justify-content-center">
                <Link
                  to="/login"
                  state={{
                    role: "investor",
                  }}
                  className="button-lg-circle rounded-circle bg-white"
                >
                  <i className="bi bi-arrow-right text-dark fs-2"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="bg-light mb-4 rounded-4 h-100">
                <div className="card-body p-6 text-center">
                  <div className="mb-4">
                    <div className="mb-3 mb-md-0">
                      <img
                        src="/assets/images/filmmaker.png"
                        alt="icon"
                        className="bg-primary icon-shape icon-xxl rounded-circle"
                      />
                    </div>
                    <div>
                      <h2 className="fw-bold mb-1">Filmmaker</h2>
                    </div>
                  </div>
                  <p className="mb-4 fs-4">
                    Fundoramaa is the top choice for filmmakers, offering
                    tailored funding solutions, direct access to investors
                  </p>
                  <Link
                    to="/filmmaker"
                    class="btn btn-outline-dark rounded-3 px-5"
                  >
                    See more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-7 mt-3 bg-light">
        <div className="container my-lg-5">
          <div className="row mt-8 justify-content-center">
            <div className="col-lg-10 col-md-12 col-12 text-center">
              <h2 className="mb-2 display-4 fw-bold">
                Get to Know{" "}
                <u class="text-primary">
                  <span class="text-dark">Fundoramaa?</span>
                </u>
              </h2>
              <p className="lead">Fund the Next Big Thing</p>
              <p className="lead">
                At Fundoramaa, we take pride in offering a powerful platform
                that seamlessly connects visionary filmmakers with eager
                investors, igniting collaboration and fuelling creativity.
              </p>

              <div className="mt-5 text-center">
                <Link to="/about" className="btn btn-dark px-8 rounded-3">
                  <div className="my-1 fs-4">Know More</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-7 pb-4">
        <div className="container mt-5">
          <div className="row py-2">
            <div className="col-12">
              <div className="row text-center">
                <div className="col-md-12 px-lg-8 mb-2">
                  <span className="text-uppercase sm-text-p text-danger fw-medium ls-md bg-primary-light px-4 py-2 rounded-pill text-white">
                    Our Process
                  </span>
                  <h2 class="fs-2 fw-bold mt-2 mb-0">
                    Exploring Fundoramaa{" "}
                    <u class="text-primary">
                      <span class="text-dark">Workflow</span>
                    </u>
                  </h2>
                </div>
              </div>
              <div className="row g-3 mt-0">
                <div className="col-lg-6 col-12">
                  <div className="text-center p-lg-7 p-3 bg-light rounded-4 h-100">
                    <h2 className="fw-normal lh-1 my-3 fw-semibold">
                      Investors
                    </h2>
                    <p className="mb-0 px-4">
                      Sign up to create your investor profile, browse matching
                      film projects, connect with creators for collaboration,
                      and invest to help bring your vision to life.
                    </p>
                    <Link
                      to="/login"
                      state={{
                        role: "investor",
                      }}
                      className="btn btn-outline-dark text-uppercase px-5 py-2 mt-4 mb-3 rounded-3"
                    >
                      Fund Now
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="text-center p-lg-7 p-3 bg-light rounded-4 h-100">
                    <h2 className="fw-normal lh-1 my-3 fw-semibold">
                      Filmmakers
                    </h2>
                    <p className="mb-0 px-4">
                      Sign up to create your filmmaker profile, explore matching
                      investors, connect for potential collaborations, and
                      secure funding to bring your vision to life.
                    </p>
                    <Link
                      to="/login"
                      state={{
                        role: "content_producer",
                      }}
                      className="btn btn-outline-dark text-uppercase px-5 py-2 mt-4 mb-3 rounded-3"
                    >
                      Get Funded
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8 my-5">
        <div className="container pb-2 pt-lg-0 pt-8 text-center">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center">
              <div className="ps-lg-8">
                <h1 class="display-3 fw-bold mb-2">
                  Connections That Empower{" "}
                  <u class="text-warning">
                    <span class="text-primary">Progress</span>
                  </u>
                </h1>
                <p class="lead mb-4">
                  Receive invoice payments directly from leading OTTs & music
                  labels for your invoices.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="image-scroll-container">
          <div className="image-scroll">
            {images.map((src, index) => (
              <img
                key={index}
                src={src}
                width="120"
                height="120"
                className="rounded-5"
                alt="OTT Image"
              />
            ))}
          </div>
        </div>
      </section>

      <section className="pb-8 mb-2">
        <div className="container">
          <div className="p-lg-8 p-3 rounded-4 bg-yellow-gradient">
            <div className="mb-4 mb-xl-0 text-center text-md-start mt-lg-0 mt-5">
              <span className="text-uppercase sm-text-p text-danger fw-medium ls-md bg-primary px-4 py-2 rounded-pill text-white">
                Fundoramaa
              </span>
              <h1 className="display-2 fw-bold my-3 ls-sm">How? What? Why?</h1>

              <div
                className="accordion accordion-flush w-100 mt-6"
                id="accordionExample"
              >
                <div class="bg-white px-4 py-3 rounded-4 mb-1">
                  <div className="py-1" id="headingOne">
                    <h3 className="mb-0 fw-bold">
                      <a
                        href="#"
                        className="d-flex align-items-center text-inherit"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <span className="me-auto fs-4 fw-normal text-dark ms-3">
                          How?
                        </span>
                        <span className="collapse-toggle ms-4">
                          <i className="bi bi-plus text-primary" />
                        </span>
                      </a>
                    </h3>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="py-4 fs-4 text-start">
                      <ol>
                        <li>
                          Platform for Filmmakers: Fundoramaa provides a
                          dedicated platform to support filmmakers' financial
                          needs.
                        </li>
                        <li>
                          Funding through Invoice Discounting: Filmmakers can
                          secure immediate funding by discounting their
                          invoices.
                        </li>
                        <li>
                          Tailored for Media & Entertainment: The process is
                          specifically customized to meet the unique financial
                          requirements of the media and entertainment industry.
                        </li>
                        <li>
                          Enhanced Cash Flow: This approach helps filmmakers
                          improve cash flow, allowing projects to continue
                          smoothly without waiting for traditional funding
                          sources.
                        </li>
                        <li>
                          Focus on Industry Needs: By addressing
                          industry-specific challenges, Fundoramaa provides a
                          more relevant and effective funding solution for
                          creative professionals.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="bg-white px-4 py-3 rounded-4 mb-1">
                  <div className="py-1" id="headingTwo">
                    <h3 className="mb-0 fw-bold">
                      <a
                        href="#"
                        className="d-flex align-items-center text-inherit"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <span className="me-auto fs-4 fw-normal text-dark ms-3">
                          What?
                        </span>
                        <span className="collapse-toggle ms-4">
                          <i className="bi bi-plus text-primary" />
                        </span>
                      </a>
                    </h3>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="py-4 fs-4 text-start">
                      <ol>
                        <li>
                          Specialized for Media & Entertainment: Fundoramaa
                          focuses exclusively on the media and entertainment
                          sector.
                        </li>
                        <li>
                          Invoice Discounting Platform: It provides a platform
                          where production houses and similar entities can list
                          their invoices.
                        </li>
                        <li>
                          Invoice Sellers: Production houses or TV producers can
                          offer their invoices for sale to improve cash flow.
                        </li>
                        <li>
                          Purchasers at a Discount: Individuals or companies can
                          purchase these invoices at a discounted rate,
                          providing liquidity to sellers.
                        </li>
                        <li>
                          Mutual Benefit: Sellers receive immediate funds, while
                          purchasers gain the opportunity to earn returns when
                          the invoices are fully paid.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="bg-white px-4 py-3 rounded-4 mb-1">
                  <div className="py-1" id="headingThree">
                    <h3 className="mb-0 fw-bold">
                      <a
                        href="#"
                        className="d-flex align-items-center text-inherit"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        <span className="me-auto fs-4 fw-normal text-dark ms-3">
                          Why should I invest?
                        </span>
                        <span className="collapse-toggle ms-4">
                          <i className="bi bi-plus text-primary" />
                        </span>
                      </a>
                    </h3>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="py-4 fs-4 text-start">
                      <ol>
                        <li>
                          Generate alpha returns; typically, 12 - 16% p.a.
                        </li>
                        <li>
                          Diversify portfolio since it is not linked to stock
                          markets
                        </li>
                        <li>Maturity in short cycles; usually 1 to 3 years.</li>
                        <li>
                          Low Volatility: Invoice discounting typically offers
                          more predictable returns compared to other high-risk
                          investments.
                        </li>
                        <li>
                          Support for Creative Ventures: Your investment
                          directly aids filmmakers and production companies,
                          helping creative projects succeed.
                        </li>
                        <li>
                          Support the storytellers of tomorrow while growing
                          your investment today on Fundoramaa.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-7">
        <div className="container my-5">
          <div className="row pb-2">
            <div className="col-12 text-center">
              <span className="text-uppercase sm-text-p text-danger fw-medium ls-md bg-primary-light px-4 py-2 rounded-pill text-white">
                For filmmakers
              </span>
              <h2 class="fs-1 mt-4 mb-0 lh-1 text-center">
                Revolutionizing Film Funding
                <br />
                for{" "}
                <u class="text-primary">
                  <span class="text-dark">Filmmaker</span>
                </u>
              </h2>
              <div className="row g-3 mt-5">
                <div className="col-lg-4 col-12">
                  <div className="light-bg-1 rounded-4 h-100">
                    <div className="card-body px-6 pb-6 pt-5">
                      <div className="my-4">
                        <div className="mb-3 mb-md-0">
                          <img
                            src="/assets/images/funding/creation.png"
                            alt="icon"
                            width="70"
                          />
                        </div>
                        <div className="mt-5 pt-2">
                          <h2 className="lh-1 mb-1">
                            Build Your Vision, We'll Manage the Money
                          </h2>
                          <p className="text-secondary mt-3 lh-sm">
                            Empowering creators to bring their visions to life,
                            without the worry of cash flow.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="light-bg-1 rounded-4 h-100">
                    <div className="card-body px-6 pb-6 pt-5">
                      <div className="my-4">
                        <div className="mb-3 mb-md-0">
                          <img
                            src="/assets/images/funding/ott.png"
                            alt="icon"
                            width="70"
                          />
                        </div>
                        <div className="mt-5 pt-2">
                          <h2 className="lh-1 mb-1">
                            Easy access to OTT channel
                          </h2>
                          <p className="text-secondary mt-3 lh-sm">
                            OTT platforms enhance content reach by offering
                            on-demand access to a global, multi-device audience,
                            boosting engagement and personalization.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="light-bg-1 rounded-4 h-100">
                    <div className="card-body px-6 pb-6 pt-5">
                      <div className="my-4">
                        <div className="mb-3 mb-md-0">
                          <img
                            src="/assets/images/funding/fundicon.png"
                            alt="icon"
                            width="70"
                          />
                        </div>
                        <div className="mt-5 pt-2">
                          <h2 className="lh-1 mb-1">Unlock funds quickly</h2>
                          <p className="text-secondary mt-3 lh-sm">
                            Fast-track funding designed to keep your production
                            moving.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="light-bg-1 rounded-4 h-100">
                    <div className="card-body px-6 pb-6 pt-5">
                      <div className="my-4">
                        <div className="mb-3 mb-md-0">
                          <img
                            src="/assets/images/funding/partner.png"
                            alt="icon"
                            width="70"
                          />
                        </div>
                        <div className="mt-5 pt-2">
                          <h2 className="lh-1 mb-1">Your Partner in Growth</h2>
                          <p className="text-secondary mt-3 lh-sm">
                            Our Account Managers are here to guide you every
                            step of the way
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="light-bg-1 rounded-4 h-100">
                    <div className="card-body px-6 pb-6 pt-5">
                      <div className="my-4">
                        <div className="mb-3 mb-md-0">
                          <img
                            src="/assets/images/funding/green.png"
                            alt="icon"
                            width="70"
                          />
                        </div>
                        <div className="mt-5 pt-2">
                          <h2 className="lh-1 mb-1">
                            Onboard with Ease, Achieve Powerful Outcomes
                          </h2>
                          <p className="text-secondary mt-3 lh-sm">
                            Get funded faster with our tech-driven process:
                            minimal docs, zero manual intervention.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 d-lg-block d-none">
                  <div className="bg-3 mb-4 rounded-4 h-100 d-flex align-items-center justify-content-center">
                    <Link
                      to="/login"
                      state={{
                        role: "investor",
                      }}
                      className="button-lg-circle rounded-circle bg-white"
                    >
                      <i className="bi bi-arrow-right text-dark fs-2"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8 mb-5">
        <div className="container">
          <div className="row pb-2">
            <div className="col-12">
              <div className="row text-center d-flex justify-content-center">
                <div className="col-lg-8 col-md-12 px-lg-8 mb-5">
                  <span className="text-uppercase sm-text-p text-danger fw-medium ls-md bg-primary-light px-4 py-2 rounded-pill text-white">
                    Projects
                  </span>
                  <h2 class="fs-2 fw-bold mt-2 mb-0">
                    Discover Top Film{" "}
                    <u class="text-primary">
                      <span class="text-dark">Projects</span>
                    </u>
                  </h2>
                  <p className="lead">
                    Discover a wide range of exciting film projects that are
                    actively seeking investment.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-3">
            {projects
              .filter((project) => project.ottUpdates === "approved")
              .slice(0, 3)
              .map((project) => (
                <>
                  <div class="col-lg-4 col-md-6 col-12">
                    <div className="card rounded-4 card-bordered card-lift">
                      <div className="d-flex flex-column gap-3">
                        <Link
                          to="/login"
                          state={{
                            role: "investor",
                          }}
                        >
                          <img
                            src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjqcgvG8llUk12xECDkIzfCCazEYxzJJ3DaqAV7rLv5BUImIMZGwUnuzM8R5bJeVMz1qR9b5C4w1Cy7QTRy0AeSJHqsb29FeooMxxnFrmE_zZdEMCa_Ye6FeMn79UuDFtKm7RlTN-DN5d5a/s1600/shubh-mangal-zyada-saavdhan-movie-star-cast-release-date-poster.jpg"
                            alt="mentor 1"
                            className="w-100 rounded-top-4 object-fit-cover object-position-top"
                            height="250"
                          />
                        </Link>
                        <div className="d-flex flex-column px-4 pb-5 pt-2">
                          <div className="d-flex flex-column gap-2">
                            <div className="d-flex align-items-center gap-2">
                              <h3 className="mb-0">
                                <Link
                                  to="/login"
                                  state={{
                                    role: "investor",
                                  }}
                                  className="text-reset"
                                >
                                  {project.projectName}
                                </Link>
                              </h3>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-patch-check-fill text-success"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <div className="mt-3 mb-4">
                            <div class="mb-2 mb-md-0">
                              <span class="me-4">
                                <i class="bi bi-camera-reels"></i>
                                <span class="ms-1 text-capitalize">
                                  {project.contentType}
                                </span>
                              </span>
                              <span class="me-2">
                                <i class="bi bi-chat-square-quote"></i>
                                <span class="ms-1 text-capitalize">
                                  {project.language}
                                </span>
                              </span>
                              <div>
                                <i class="bi bi-calendar-week me-1"></i>{" "}
                                <span className="text-uppercase">
                                  {new Date(project.createdAt).toLocaleString(
                                    "en-GB",
                                    {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    }
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="progress mb-4" role="progressbar">
                              <div class="progress-bar w-15 rounded-pill"></div>
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <div>
                              <span>Goal</span>
                              <div className="d-flex flex-row gap-1 align-items-center">
                                <h4 className="mb-0">
                                  {new Intl.NumberFormat("en-IN").format(
                                    project.tentativeBudget
                                  )}
                                </h4>
                              </div>
                            </div>
                            <div>
                              <Link
                                to="/login"
                                state={{
                                  role: "investor",
                                }}
                                className="btn btn-outline-primary px-5 rounded-pill"
                              >
                                Invest Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container mb-4">
          <div className="row">
            <div className="col-lg-6 col-12">
              <img src="/assets/images/cal.png" className="w-100 rounded-4" />
            </div>
            <div className="col-lg-6 col-12 d-flex flex-column justify-content-center">
              <div className="ps-lg-6 mt-lg-0 mt-5">
                <span className="text-uppercase sm-text-p text-danger fw-medium ls-md bg-primary-light px-4 py-2 rounded-pill text-white">
                  Returns Calculator
                </span>
                <h1 class="display-2 fw-bold mb-3 mt-3 lh-1">
                  Calculate potential{" "}
                  <u class="text-warning">
                    <span class="text-primary">returns</span>
                  </u>
                </h1>
                <div>
                  <p className="fs-4 mb-0 mt-4">Investment amount</p>
                  <span className="fs-1 fw-semibold text-dark">₹</span>
                  <input
                    className="fs-1 fw-semibold w-75 border-0 min-text-spacing"
                    type="text"
                    value={investmentAmount.toLocaleString("en-IN")}
                    onChange={handleInvestmentChange}
                  />

                  <p className="fs-4 mb-3 mt-2">Tenure</p>
                  <div>
                    {["1 Year", "2 Years", "3 Years"].map((label, index) => {
                      const year = index + 1;
                      return (
                        <span
                          key={year}
                          className={`fs-5 px-4 py-2 me-3 rounded-pill ${
                            tenure === year
                              ? "text-white bg-primary"
                              : "text-dark bg-white"
                          }`}
                          onClick={() => handleTenureChange(year)}
                          style={{ cursor: "pointer" }}
                        >
                          {label}
                        </span>
                      );
                    })}
                  </div>

                  <div className="bg-light px-5 py-3 rounded-bottom-0 rounded-3 mt-5">
                    <p className="mb-1 fs-4">Would become</p>
                    <h1 class="fs-1 fw-semibold mb-3 lh-1 min-text-spacing">
                      ₹{" "}
                      {wouldBecome.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                      })}
                    </h1>
                  </div>
                </div>
                <Link
                  to="/login"
                  state={{
                    role: "investor",
                  }}
                  class="btn btn-dark btn-lg rounded-top-0 px-5 w-100"
                >
                  Invest Now <i className="bi bi-arrow-right ms-3"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-7">
        <div className="container mt-3">
          <div className="p-8 rounded-4 primary-bg d-flex justify-content-center">
            <div className="col-lg-7 col-12 my-lg-4 my-0">
              <div className="mb-4 mb-xl-0 text-center text-md-start pe-lg-8">
                <div className="d-flex justify-content-center mb-4">
                  <div className="button-lg-circle rounded-circle bg-light">
                    <i className="bi bi-google fs-2"></i>
                  </div>
                </div>
                <h1 className="display-3 fw-bold text-white mb-3 ls-sm text-center text-uppercase lh-1">
                  4.3 / 5 on Google from 236,952 reviews
                </h1>
                <div className="my-5 mb-0 d-flex justify-content-center">
                  <a
                    href="#"
                    class="btn btn-light btn-lg rounded-pill mt-4 px-5"
                  >
                    <span className="mx-3">Get Started</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container mt-5">
          <div className="row pb-2">
            <div className="col-12">
              <div className="row text-center d-flex justify-content-center">
                <div className="col-lg-8 col-md-12 px-lg-8 mb-5">
                  <span className="text-uppercase sm-text-p text-danger fw-medium ls-md bg-primary-light px-4 py-2 rounded-pill text-white">
                    Testimonials
                  </span>
                  <h2 class="fs-2 fw-bold mt-2 mb-0">
                    Love from{" "}
                    <u class="text-primary">
                      <span class="text-dark">Customers</span>
                    </u>
                  </h2>
                  <p className="lead">
                    Don't just take our word for it. Hear what our customers
                    have to say about their experience with Company!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="impressiveDesign"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div className="row g-3">
                  <div className="col-lg-6 col-12">
                    <div className="bg-light px-6 py-6 rounded-4 text-center h-100">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <h2 className="fs-1 mb-0">
                            ₹1.4 <span className="fs-3">CR</span>
                          </h2>
                          <p className="fs-4">Capital Funded</p>
                        </div>
                        <div className="col-lg-6 col-12">
                          <img
                            src="/assets/images/customers/visualey.png"
                            width="80"
                            className="rounded-circle mb-3"
                          />
                          <h2 className="fs-3 mb-0">Visualey</h2>
                          <p className="fs-4">Media Production</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="bg-light px-6 py-6 rounded-4 text-center h-100">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <h2 className="fs-1 mb-0">
                            ₹1.0 <span className="fs-3">CR</span>
                          </h2>
                          <p className="fs-4">Capital Funded</p>
                        </div>
                        <div className="col-lg-6 col-12">
                          <img
                            src="/assets/images/customers/hada.png"
                            width="80"
                            className="mb-3"
                          />
                          <h2 className="fs-3 mb-0">Hada Films</h2>
                          <p className="fs-4">Media Production</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-6">
        <div className="container mt-5">
          <div className="row pt-2">
            <div className="col-12">
              <div className="row text-center d-flex justify-content-center">
                <div className="col-lg-8 col-md-12 px-lg-8 mb-6">
                  <span className="text-uppercase sm-text-p text-danger fw-medium ls-md bg-primary-light px-4 py-2 rounded-pill text-white">
                    FAQs
                  </span>
                  <h2 class="fs-2 fw-bold mt-2 mb-0">
                    Frequently Asked{" "}
                    <u class="text-primary">
                      <span class="text-dark">Questions</span>
                    </u>
                  </h2>
                  <p className="lead">
                    Don't just take our word for it. Hear what our customers
                    have to say about their experience with Company!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 pb-lg-0 pb-5 d-flex align-items-center">
              <div
                className="accordion accordion-flush w-100 pe-lg-5 pe-0 me-lg-5 me-0"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        What is fundoramaa?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Fundoramaa is a dynamic platform designed to bridge the
                        gap between filmmakers and investors, empowering
                        creators to bring their projects to life with the
                        necessary funding and resources. By offering an
                        intuitive and collaborative space, Fundoramaa
                        facilitates connections that inspire and drive
                        innovation within the film industry, helping visionary
                        filmmakers turn concepts into cinematic realities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        How fundoramaa curates the opportunities?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Facilitating Invoice Discounting Opportunities for
                        purchaser and seller in Media & Entertainment Industry
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button px-0 bg-white collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <h3 className="faqs-text text-dark fw-medium m-0">
                        How does fundoramaa mitigate the risk of default?
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                    style={{}}
                  >
                    <div className="accordion-body px-0">
                      <p className="fs-4 fw-light mb-4 text-secondary">
                        Before listing the opportunity, Fundoramaa signs a deal
                        with the production house for the direct transfer of
                        revenue from the OTT to the purchasers. It is the
                        leading OTTs that pay you and hence minimal worries of
                        default
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-4 mb-lg-0">
                <img
                  src="/assets/images/faq.png"
                  alt="featured"
                  className="w-100 rounded-4 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
          </div>
          <div className="mt-8 text-center">
            <Link
              to="/contact"
              className="btn btn-outline-dark px-8 mb-6 rounded-3"
            >
              <div className="my-1 fs-4">More questions? Contact Us</div>
            </Link>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Home;
